.nav-item {
    position: relative;
}

.nav-item::after {
    content: '';
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: transparent;
}

.nav-item__mennu {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 9999;
}

.nav-item:hover + .nav-item__mennu,
.nav-item__mennu:hover {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
}
