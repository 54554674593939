/* Styles PhoneInput-2*/
.nucleus.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background: transparent;
}
.nucleus .selected-flag .flag {
    border-radius: 50%;
    width: 13px;
    height: 13px;
    opacity: 0.6;
}
.nucleus.react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent;
}
.nucleus.react-tel-input .form-control {
    background: #f4f6f8;
}

/*  Double Range Slider */

@layer {
    .double__slider .progress {
        /* height: 100% !important; */
        margin: 0 auto;
        background: theme('colors.primary');
    }
    .double__slider input[type='range']::-webkit-slider-thumb {
        pointer-events: auto;
    }
}

input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}
