


/*第一欄第一列：左上*/

/*第一欄最後列：左下*/
.table__grid tr:last-child td:first-child{
  border-bottom-left-radius: 10px;


}

/*最後欄第一列：右上*/

/*最後欄第一列：右下*/
.table__grid tr:last-child{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;


    
}
.table__grid tr:last-child td:last-child{
  border-bottom-right-radius: 10px;

}