@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;500;700;800&family=Sen:wght@400;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    * {
        --tw-clip-polygon: var(--tw-clip-polygon-a), var(--tw-clip-polygon-b), var(--tw-clip-polygon-c),
            var(--tw-clip-polygon-d);
        --tw-clip-polygon-a: 0 0;
        --tw-clip-polygon-b: 100% 0;
        --tw-clip-polygon-c: 100% 100%;
        --tw-clip-polygon-d: 0 100%;
    }

    .clip.polygon.a-3 {
        --tw-clip-polygon-a: 0 2.25rem;
    }

    .clip.polygon.b-3 {
        --tw-clip-polygon-b: 100% 2.25rem;
    }

    .clip.polygon.c-3 {
        --tw-clip-polygon-c: 100% calc(100% - 2.25rem);
    }

    .clip.polygon.c-60 {
        --tw-clip-polygon-c: 100% 60%;
    }

    .clip.polygon.c-75 {
        --tw-clip-polygon-c: 90% 100%;
    }

    .clip.polygon.d-3 {
        --tw-clip-polygon-d: 0 calc(100% - 2.25rem);
    }

    .clip.polygon {
        clip-path: polygon(var(--tw-clip-polygon));
    }

    @variants responsive {
        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }
}

@layer components {
    .checkbox-label:has(input:checked) {
        @apply bg-primary;
    }

    .text-shadow-outer {
        text-shadow: -5px -10px 20px #ffffff, 5px 10px 20px rgba(66, 80, 109, 0.25);
    }

    .font-sen {
        font-family: 'Sen', sans-serif;
    }

    .shadow-box-inner {
        box-shadow: inset 2px 2px 2px rgba(66, 80, 109, 0.25), inset -2px -2px 2px #ffffff;
    }

    .shadow-box-outer {
        box-shadow: -5px -10px 20px #ffffff, 5px 10px 20px rgba(66, 80, 109, 0.25);
    }

    .shadow-nav {
        box-shadow: -5px -10px 20px rgba(255, 255, 255, 0.75), 5px 10px 20px rgba(66, 80, 109, 0.25),
            inset 2px 2px 2px rgba(66, 80, 109, 0.25), inset -2px -2px 2px #ffffff;
    }
    .shadow-light {
        box-shadow: 5px 10px 20px 0px rgba(66, 80, 109, 0.25), -5px -10px 20px 0px #fff;
    }

    .container-table {
        width: 100%;
    }

    @media (min-width: 640px) {
        .container {
            max-width: 640px;
        }
    }

    @media (min-width: 768px) {
        .container {
            max-width: 768px;
        }
    }

    @media (min-width: 1024px) {
        .container {
            max-width: 1024px;
        }
    }

    @media (min-width: 1280px) {
        .container {
            max-width: 1280px;
        }
    }

    @media (min-width: 1536px) {
        .container {
            max-width: 1536px;
        }
    }

    /* Customize Progess */

    .progress {
        border-radius: 30px;
        height: 10px;
        outline: none;
        transition: background 450ms ease-in;
        -webkit-appearance: none;
    }

    .progress-primary::-webkit-slider-thumb {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        -webkit-appearance: none;
        cursor: ew-resize;
        background: theme('colors.primary');
        position: relative;
        outline: 3px solid rgba(255, 255, 255, 0.5);
        outline-offset: 0px;
        box-shadow: -2px 3px 5px rgba(66, 80, 109, 0.1);
    }

    .progress-primary.read__only::-webkit-slider-thumb {
        display: none;
    }

    .progress-error::-webkit-slider-thumb {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        -webkit-appearance: none;
        cursor: ew-resize;
        background: theme('colors.error');
        position: relative;
        outline: 3px solid rgba(255, 255, 255, 0.5);
        outline-offset: 0px;
        box-shadow: -2px 3px 5px rgba(66, 80, 109, 0.1);
    }

    .tooltip_arrow::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 20px;
        right: 8px;
        border-top: 8px solid theme('colors.white-light');
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid transparent;
    }
}

html {
    @apply text-blue-dark;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;

    -moz-osx-font-smoothing: grayscale;

    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

code {
    font-family: 'Inter', sans-serif;
}

.radio input:checked ~ label {
    background-color: rgb(70, 230, 22);
    color: white;
}

/* Customize Toolbar React QUill */

#nucleus__quill .ql-toolbar button {
    border: solid 1px #cfd6e4;
    border-radius: 10px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#nucleus__quill .ql-toolbar svg {
    height: 15px;
    width: 15px;
}

#nucleus__quill .ql-picker-label {
    width: 100%;
    height: 100%;
}

#nucleus__quill .ql-picker {
    width: 100%;
    height: 100%;
}

* {
    box-sizing: border-box;
}
/* LazyLoad */
.lazy-load-image-background {
    display: block !important;
}
